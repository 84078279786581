import $ from "jquery";
import 'select2'; // Ensure you've imported select2

const addFieldStaff = () => {
  $("form").on("click", ".add_events_staff_field", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    var newField = $($(this).data("fields").replace(regexp, time));
    $("#events_staffs_container").append(newField);
    newField.find('select').select2(); // Apply select2 to the new field
    return event.preventDefault();
  });
};

export { addFieldStaff };